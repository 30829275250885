<div class="section">
  @if (section && visibilityControl.isShown()) {
    <div class="section-title">
      @if (section.title) {
        <h5>{{ section.title | localeString }}</h5>
      }
      <div class="spacer"></div>
      <hpm-section-notes class="notes-button" [section]="section"
                         [hygieneInspectionDisabled]="hygieneInspectionDisabled"></hpm-section-notes>
    </div>
    @for (question of section.questions; track question) {
      <div class="question">
        <hpm-question
          [areaName]="areaName"
          [question]="question"
          [visibilityControl]="visibilityController.buildVisibilityControlForQuestion(question)"
          [visibilityController]="visibilityController"
          [hygieneInspectionDisabled]="hygieneInspectionDisabled"
        ></hpm-question>
      </div>
    }
  }
</div>
