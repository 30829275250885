import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { DetailTextField } from '../../../model/detail-questions.model';

@Component({
  selector: 'hpm-text-field',
  templateUrl: './text-field.component.html',
  styleUrl: './text-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TextFieldComponent {
  @Input() detail!: DetailTextField;
  @Input() hygieneInspectionDisabled = false;
}
