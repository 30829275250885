import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, EMPTY, Subject, takeUntil } from 'rxjs';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  User,
  UserRole,
} from '../../../../shared/model/user.model';
import { UserHttpService } from '../../../../shared/service/user-http.service';
import { DataHttpService } from '../../../../shared/service/data-http.service';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { OrderStateEnum } from '../../../../shared/model/order-state.enum';
import { TranslateService } from '@ngx-translate/core';
import { DocumentHttpService } from '../../../../shared/service/document-http.service';
import { MultiSearchSelectComponent } from '../../../../shared/util/multi-search-select/multi-search-select.component';
import { TemplateHttpService } from '../../../../template/template-overview/template-http.service';
import { RemoteAPIHttpService } from '../../../../shared/service/remoteAPI-http.service';
import { CrmRecord } from '../../../../shared/model/remoteAPI.model';
import { Order } from '../../../order.model';

export const reasons: string[] = [
  'Neuer Hygieneplan',
  'Nachbearbeitung',
  'Folgebegehung',
];

@Component({
  selector: 'hpm-general-form',
  templateUrl: './general-form.component.html',
  styleUrl: './general-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralFormComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >();
  private onDestroy$: Subject<void> = new Subject<void>();
  orderForm!: FormGroup;
  specialties: string[] = [];
  users: User[] = [];
  assignedTo: User | undefined;
  isInspectionDateValid = false;
  isInspectionProtocolAttached = false;
  isFormDisabled = false;
  currentUser: AuthorizedUser | null = null;

  protected readonly reasons = reasons;
  plans: string[] = [];
  multiFieldRequired = true;

  showCustomerTransactionNumberField = false;

  @ViewChild('multiSearchSelectComponent')
  multiSearchSelectComponent!: MultiSearchSelectComponent;

  //prevent file-uploader pop-up
  @HostListener('document:keydown.enter', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    event.preventDefault();
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserHttpService,
    private dataService: DataHttpService,
    private templateHttpService: TemplateHttpService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private snackbarService: CxSnackbarService,
    private documentHttpService: DocumentHttpService,
    private remoteAPIService: RemoteAPIHttpService,
  ) {
    this.orderForm = this.formBuilder.group(
      {
        customerTransactionNumber: [''],
        customer: ['', Validators.required],
        contact: ['', Validators.required],
        telephone: [],
        email: [],
        hygieneRepresentative: [],
        address: [
          { value: '', disabled: true, validators: Validators.required },
        ],
        medicalPractice: [[], { updateOn: 'change' }],
        reason: [],
        commissionedBy: [],
        status: ['', { updateOn: 'change' }],
        priority: ['', { updateOn: 'change' }],
        priorityReason: [''],
        assignedTo: [],
        specialtyField: [
          [],
          { updateOn: 'change', validators: Validators.required },
        ],
        requiredHygienePlans: [
          [],
          { updateOn: 'change', validators: Validators.required },
        ],
        createdBy: this.formBuilder.group({
          username: [{ value: '', disabled: true }],
        }),
      },
      { updateOn: 'blur' },
    );
  }

  ngOnInit(): void {
    this.prepareForm();
    this.saveOnValueChanges();
    this.loadData();
    this.userService.loadCurrentUser();
    this.loadUserlist();
    this.patchOrderToForm();
    this.addPriorityReasonValidator();
    this.addSpecialtyFieldValidator();

    this.orderForm
      .get('assignedTo')
      ?.valueChanges.pipe()
      .subscribe((assignedTo) => {
        this.enableFormOnUser(assignedTo);
      });
  }

  private addPriorityReasonValidator(): void {
    const priorityControl = this.orderForm.get('priority');
    const priorityReasonControl = this.orderForm.get('priorityReason');
    priorityControl?.valueChanges.subscribe((priority) => {
      if (priority) {
        // for setting validations
        priorityReasonControl?.setValidators(Validators.required);
      } else {
        priorityReasonControl?.clearValidators();
      }
      priorityReasonControl?.updateValueAndValidity();
    });

    // the validation depends on the status of the loaded data, after loading the trigger must be set correctly once.
    if (priorityControl?.getRawValue() === true) {
      priorityReasonControl?.setValidators(Validators.required);
      priorityReasonControl?.updateValueAndValidity();
    }
  }

  private addSpecialtyFieldValidator(): void {
    const specialtyFieldControl = this.orderForm.get('specialtyField');
    specialtyFieldControl?.valueChanges.subscribe((value) => {
      if (value === null) {
        this.translateService
          .get('ORDER_DETAIL.GENERAL.FORM.LABEL.STATUS_ERROR_MESSAGE')
          .subscribe((res: string) => {
            specialtyFieldControl?.setErrors({
              customError: true,
              errorMessage: res,
            });
          });
      } else {
        specialtyFieldControl?.setErrors(null); // Clear the errors if value is not null
      }
    });
  }

  private saveOnValueChanges(): void {
    this.orderForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        const newOrder = this.orderForm.value;
        Object.assign(this.order, newOrder);
        this.orderChange.emit();
      });
  }

  private loadData(): void {
    this.dataService
      .getSpecialties()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((specialties) => {
        this.specialties = specialties;
        this.cdr.detectChanges();
      });

    this.templateHttpService
      .getNames()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templateNames) => {
        this.plans = templateNames;
        this.cdr.detectChanges();
      });
  }

  private loadUserlist(): void {
    this.userService
      .getUserList()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((userList) => {
        this.users = userList;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  compareUser(user1: User, user2: User): boolean {
    return user2?.username === user1?.username;
  }

  private patchOrderToForm(): void {
    this.isInspectionDateValid = this.order.inspectionDate !== null;
    this.orderForm.patchValue(this.order, { emitEvent: false });
  }

  protected prepareForm(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;

        if (currentUser !== null) {
          this.showTransactionFieldOnUser();

          this.enableFormOnUser(this.order.assignedTo);
        }
      });
  }

  private showTransactionFieldOnUser(): void {
    if (hasAnyOfThoseRoles(this.currentUser, [
        UserRole.OFFICE_SERVICE,
        UserRole.FIELD_SERVICE,
        UserRole.ADMIN,
      ])
    ) {
      this.showCustomerTransactionNumberField = true;
    }
  }

  private enableFormOnUser(assignedUser: User): void {
    if (this.currentUser?.username !== assignedUser.username) {
      this.disableForm();
    } else {
      this.enableForm();
      this.enableAddressFieldForRetailPartner();
    }
    this.enableAssignedToOnUser();
    this.cdr.detectChanges();
  }

  private enableForm(): void {
    this.orderForm.enable({ emitEvent: false });
    this.isFormDisabled = false;
  }

  private disableForm(): void {
    this.orderForm.disable({ emitEvent: false });
    this.isFormDisabled = true;
  }

  private enableAddressFieldForRetailPartner(): void {
    if (!hasAnyOfThoseRoles(this.currentUser, [UserRole.RETAIL_PARTNER])) {
      this.orderForm.controls['address'].disable({ emitEvent: false });
    } else {
      this.orderForm.controls['address'].enable({ emitEvent: false });
    }
  }

  private enableAssignedToOnUser(): void {
    if (
      hasAnyOfThoseRoles(this.currentUser, [
        UserRole.OFFICE_SERVICE,
        UserRole.ADMIN,
      ])
    ) {
      this.orderForm.controls['assignedTo'].enable({
        emitEvent: false,
      });
    } else {
      this.orderForm.controls['assignedTo'].disable({
        emitEvent: false,
      });
    }
  }

  private showValidationErrors(): void {
    this.translateService
      .get('ORDER_DETAIL.GENERAL.FORM.LABEL.STATUS_ERROR_MESSAGE')
      .subscribe((res: string) => {
        this.snackbarService.error(res, 'close');
      });
  }

  validateForm(): boolean {
    this.multiSearchSelectComponent.updateValueAndValidity();
    this.orderForm.markAllAsTouched();
    return !this.orderForm.invalid;
  }

  validateInspectionDateAndProtocolDocuments(): boolean {
    return this.isInspectionDateValid && this.isInspectionProtocolAttached;
  }

  isStatusChangeValid(): boolean {
    return (
      this.validateForm() && this.validateInspectionDateAndProtocolDocuments()
    );
  }

  onStatusChange(event: {
    oldStatus: OrderStateEnum;
    newStatus: OrderStateEnum;
    assignedTo: User;
  }): void {
    const statusChangeNeedValidation =
      event.oldStatus === OrderStateEnum.IN_CAPTURE &&
      event.newStatus === OrderStateEnum.IN_INCOMING_INSPECTION;
    if (statusChangeNeedValidation) {
      this.documentHttpService
        .getProtocolDocumentsForOrder(this.order.id!)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((documentList) => {
          this.isInspectionProtocolAttached = documentList.length > 0;
          if (this.isStatusChangeValid()) {
            this.setStatus(event.newStatus, event.assignedTo);
          } else {
            this.showValidationErrors();
            this.orderForm.controls['status'].setValue(event.oldStatus);
          }
        });
    } else {
      this.setStatus(event.newStatus, event.assignedTo);
    }
  }

  setStatus(selectedStatus: OrderStateEnum, assignedTo: User): void {
    this.orderForm.patchValue({
      status: selectedStatus,
      assignedTo,
    });
  }

  lookupCRMData(): void {
    const id = this.orderForm.get('customerTransactionNumber')?.value;
    if (id) {
      this.remoteAPIService
        .getCaseFromCRM(id)
        .pipe(
          takeUntil(this.onDestroy$),
          catchError(() => {
            this.orderForm.get('customerTransactionNumber')?.setErrors({
              customError: true,
              errorMessage: 'Case ID not found',
            });
            return EMPTY;
          }),
        )
        .subscribe((record) => {
          if (record) {
            this.copyCRMDataIntoForm(record);
          }
        });
    }
  }

  private copyCRMDataIntoForm(record: CrmRecord): void {
    const accountName = record?.account?.name;
    if (accountName) {
      this.orderForm.get('customer')?.setValue(accountName);
    }
    const phone = record?.contact?.phone;
    if (phone) {
      this.orderForm.get('telephone')?.setValue(phone);
    }
    const email = record?.contact?.email;
    if (email) {
      this.orderForm.get('email')?.setValue(email);
    }
    const academicTitle = record?.contact?.academicTitle;
    const name = record?.contact?.name;
    if (name) {
      let formalName = name;
      if (academicTitle) {
        formalName = academicTitle + ' ' + name;
      }
      this.orderForm.get('contact')?.setValue(formalName);
    }

    const street = record?.account?.street;
    const zip = record?.account?.zip;
    const city = record?.account?.city;
    const country = record?.account?.country;

    if (street || zip || city || country) {
      const address =
        (street ? `${street}, ` : '') +
        (zip ? `${zip} ` : '') +
        (city ? `${city}` : '') +
        (country ? `, ${country}` : '');

      const enabled = this.orderForm.get('address')?.enabled;
      this.orderForm.get('address')?.enable();
      this.orderForm.get('address')?.setValue(address);
      if (!enabled) {
        this.orderForm.get('address')?.disable();
      }
    }

    const medicalSpecialities = record?.account?.medicalSpecialities;

    if (medicalSpecialities) {
      this.orderForm.get('specialtyField')?.patchValue(medicalSpecialities);
    }

    this.saveOnValueChanges();
  }
}
