import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'hpm-medical-practise',
  templateUrl: './medical-practise.component.html',
  styleUrl: './medical-practise.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedicalPractiseComponent),
      multi: true,
    },
  ],
})
export class MedicalPractiseComponent implements ControlValueAccessor {
  readonly MAX_LIMIT = 10;
  medicalPractises: string[] = [];
  @Input() isDisabled: boolean = false;
  @ViewChildren('medicalPractiseInput')
  medicalPractiseInputs: QueryList<ElementRef> | undefined;
  @ViewChildren('medicalPractiseButton')
  medicalPractiseButtons: QueryList<MatButton> | undefined;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */ // any type required for CVA interface
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* eslint-enable */

  writeValue(value: string[]): void {
    if (value) {
      this.medicalPractises = value;
    } else {
      this.medicalPractises = [];
    }
    this.cdr.detectChanges();
  }

  updateValue(input: FocusEvent, index: number): void {
    if (input?.target as EventTarget) {
      // @ts-expect-error value is read from input.target.value
      const newValue: string = input.target['value'];
      if (newValue && newValue.length > 0) {
        this.medicalPractises[index] = newValue;
        this.onChange(this.medicalPractises);
      } else {
        this.removeInput(index);
      }
      if (this.medicalPractiseButtons) {
        this.medicalPractiseButtons.get(index)?.focus();
        if (input.relatedTarget) {
          this.renderer.selectRootElement(input.relatedTarget, true).focus();
        }
      }
      this.cdr.detectChanges();
    }
  }

  removeInput(index: number): void {
    this.medicalPractises.splice(index, 1);
    this.onChange(this.medicalPractises);
  }

  addInput(): void {
    this.medicalPractises.push('');
    this.cdr.detectChanges();
    this.medicalPractiseInputs?.last.nativeElement.focus();
  }

  isAddButtonVisible(): boolean {
    return (
      !!this.medicalPractises && this.medicalPractises?.length < this.MAX_LIMIT
    );
  }
}
