<div
  *ngFor="let medicalPractise of medicalPractises; index as i"
  class="medicalPractise"
>
  <mat-form-field>
    <mat-label>{{ "ORDER_DETAIL.GENERAL.FORM.LABEL.MEDICAL_PRACTICE" | translate }}{{ i + 1 }}</mat-label>
    <input #medicalPractiseInput (blur)="updateValue($event, i)" [value]="medicalPractise" class="medicalPractiseInput"
           matInput/>
  </mat-form-field>
  <button #medicalPractiseButton (click)="removeInput(i)" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="isAddButtonVisible()" class="addButtonContainer">
  <mat-label
    [ngClass]="{'disabled-text': isDisabled} ">{{ "ORDER_DETAIL.GENERAL.FORM.LABEL.MEDICAL_PRACTICE" | translate }}
  </mat-label>
  <div>
    <button (click)="addInput()" class="cx-button-with-icon" type="button" [disabled]="isDisabled"
            mat-stroked-button>
      <mat-icon class="cx-icon-in-button">add</mat-icon>
      {{ "ORDER_DETAIL.GENERAL.FORM.LABEL.ADD_MEDICAL_PRACTICE" | translate }}
    </button>
  </div>
</div>
