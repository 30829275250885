<div>
    <mat-form-field>
      <mat-label>{{ detail.label | localeString  }}</mat-label>
      <textarea cdkTextareaAutosize matInput
                [disabled]="hygieneInspectionDisabled"
                hpmAreaChangeListener
                [(ngModel)]="detail.value"></textarea>
    </mat-form-field>
  </div>

