<form (ngSubmit)="$event.preventDefault()">
  <div class="logo-column">
    <mat-label [ngClass]="{'disabled-text': isDisabled} ">{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.LOGO' | translate }}
    </mat-label>
    <div class="praxislogo">
      <cx-image height="14rem"
                [imgSrc]="imageSrc"
                (click)="showUploaderDialog()"
                (imageError)="showUploaderDialog()"
      ></cx-image>
    </div>
    <div class="logo-uploader" [hidden]="!uploaderVisible">
      <cx-file-uploader
        [buttonText]="'ORDER_DETAIL.GENERAL.FORM.LABEL.UPLOADER.BUTTON' | translate"
        [description]="'ORDER_DETAIL.GENERAL.FORM.LABEL.UPLOADER.DESCRIPTION' | translate"
        [errorMessage]="'ORDER_DETAIL.GENERAL.FORM.LABEL.UPLOADER.ERROR_MESSAGE' | translate"
        [heading]="'ORDER_DETAIL.GENERAL.FORM.LABEL.UPLOADER.HEADING' | translate"
        [acceptedFileTypes]="uploaderAcceptedFiletypes"
        [files]="files"
        [manualUpload]="true"
        (selectFile)="fileSelected($event)"
        (removeFile)="fileRemoved($event)"
        [disabled]="isDisabled"
      ></cx-file-uploader>
      <div class="delete-logo" *ngIf="imageSrc.length > 1 && uploaderVisible">
        <h3 class="delete-title" [ngClass]="{'disabled-text': isDisabled} " >
          {{ 'ORDER_DETAIL.GENERAL.FORM.LOGO.DELETE_HEADLINE' | translate }} </h3>
        <button color="accent" mat-flat-button [disabled]="isDisabled"
                [hpmConfirmDelete]="'ORDER_DETAIL.GENERAL.FORM.LABEL.LOGO' | translate"
                (confirmation)="deleteLogo($event)">
          {{ 'ORDER_DETAIL.GENERAL.FORM.LOGO.DELETE_BUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
