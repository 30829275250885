@for (product of detail.products; track product) {
  <div class="cleaning-device-solution">
    <hpm-product-field [area]="area"
                       [questionId]="questionId"
                       [product]="product"  [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                       (selectedProductProposal)="onSelectProductProposal($index, $event)">
    </hpm-product-field>

    <mat-form-field class="input-field">
      <mat-select hpmAreaChangeListener
                  [(ngModel)]="product.time"
                  [disabled]="hygieneInspectionDisabled"
                  (selectionChange)="changedProductTime($index)">
        <mat-option value="{{deviceSolutionEfficiency.KURZZEITWERT}}">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CLEANING_DEVICE_SOLUTION.SHORT' | translate }}</mat-option>
        <mat-option value="{{deviceSolutionEfficiency.LANGZEITWERT}}">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CLEANING_DEVICE_SOLUTION.LONG' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <hpm-contact-time-field class="input-field"
                            [(applicationTime)]="product.applicationTime"
                            [productProposal]="selectedProductProposal.get($index)"
                            [hygieneInspectionDisabled]="hygieneInspectionDisabled"
    >
    </hpm-contact-time-field>

    <div class="addAndRemoveButtons">
      <button (click)="removeLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
        <mat-icon>remove</mat-icon>
      </button>
      <button (click)="addLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  @if (showCleaningDeviceSolutionRow()) {
      <hpm-cleaning-solution-row class="cleaning-solution-input"
                                 [label]="detail.label | localeString"
                                 [introduction]="detail.introduction | localeString"
                                 [product]="product"
                                 [hygieneInspectionDisabled]="hygieneInspectionDisabled"
      />
  }
}

