import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { RequestedDocument } from './requestedDocument';
import { RequestedDocumentsService } from '../../../../../shared/service/requestedDocuments.service';
import { MatSortable } from '@angular/material/sort';
import { PageRequest } from '../../../../../shared/model/page-request.model';
import { Order } from '../../../../order.model';
import { PageEvent } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { UserHttpService } from '../../../../../shared/service/user-http.service';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../../../../shared/model/user.model';

@Component({
  selector: 'hpm-document-table',
  templateUrl: './document-table.component.html',
  styleUrl: './document-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DocumentTableComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();
  @Input() documentsDisabled = false;
  requestedDocuments: RequestedDocument[] = [];
  private onDestroy$: Subject<void> = new Subject();
  currentUser: AuthorizedUser | null = null;
  columns: string[] = [
    'title',
    'version',
    'quantity',
    'lastUpdate',
    'status',
    'menu',
  ];
  readonly defaultPageSize: number = 10;

  tableLoading = false;
  totalElementCount = 0;
  tableSettings: {
    pageSize: number;
    pageNumber: number;
    sort: { id: string; start: 'asc' | 'desc' | '' };
  } = {
    pageSize: this.defaultPageSize,
    pageNumber: 0,
    sort: { id: 'title', start: 'asc' },
  };

  constructor(
    private requiredDocumentsService: RequestedDocumentsService,
    private cdr: ChangeDetectorRef,
    private userService: UserHttpService,
  ) {}

  ngOnInit(): void {
    this.loadRequiredDocuments();
    this.getCurrentUser();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSortingDirectionChanged(sortData: MatSortable): void {
    this.tableSettings.pageNumber = 0;
    this.tableSettings.sort = {
      start: sortData.start,
      id: sortData.id,
    };
    this.loadRequiredDocuments();
  }

  onPaginatorChange(changeData: PageEvent): void {
    this.tableSettings.pageNumber = changeData.pageIndex;
    this.tableSettings.pageSize = changeData.pageSize;
    this.loadRequiredDocuments();
  }

  private loadRequiredDocuments(): void {
    const pageRequest = new PageRequest({
      page: this.tableSettings.pageNumber,
      size: this.tableSettings.pageSize,
    });
    if (this.tableSettings.sort && this.tableSettings.sort.start !== '') {
      this.tableLoading = true;
      pageRequest.sort = this.tableSettings.sort;
    }
    if (this.order.id) {
      this.tableLoading = true;
      this.requiredDocumentsService
        .getRequestedDocumentsForOrder(this.order.id, pageRequest)
        .subscribe((requiredDocuments) => {
          this.requestedDocuments = requiredDocuments.content;
          this.totalElementCount = requiredDocuments.totalElements;
          this.tableLoading = false;
          this.cdr.detectChanges();
        });
    }
  }

  setQuantityForDocument(documentIndex: number, value: number): void {
    if (documentIndex >= this.requestedDocuments.length || value < 0) {
      return;
    }
    const newRequestedDocument: RequestedDocument =
      this.requestedDocuments[documentIndex];

    newRequestedDocument.quantity = value;
    this.updateRequestedDocuments(newRequestedDocument);
  }

  getRequestedDocumentQuantity(documentIndex: number): number {
    if (documentIndex >= this.requestedDocuments.length) {
      return 0;
    }
    return this.requestedDocuments[documentIndex].quantity;
  }

  private updateRequestedDocuments(
    newRequestedDocument: RequestedDocument,
  ): void {
    this.requiredDocumentsService
      .updatedRequestedDocuments(newRequestedDocument)
      .subscribe();
  }

  generateDocument(id: string): void {
    this.requiredDocumentsService
      .generateDocument(this.order.id!, id)
      .subscribe(() => {
        this.loadRequiredDocuments();
      });
  }

  getDocxUrl(requestedDocumentId: string): string {
    return this.requiredDocumentsService.getDocxDownloadEndpoint(
      this.order.id!,
      requestedDocumentId,
    );
  }

  getCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      });
    this.cdr.detectChanges();
  }

  allowedToGenerateOrEdit(): boolean {
    return hasAnyOfThoseRoles(this.currentUser, [
      UserRole.ADMIN,
      UserRole.OFFICE_SERVICE,
    ]);
  }

  allowedToDownload(document: RequestedDocument): boolean {
    return hasAnyOfThoseRoles(this.currentUser, [
      UserRole.ADMIN,
      UserRole.OFFICE_SERVICE,
    ]) || document.status === 'COMPLETED';
  }
}
